import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createOtherYuorderCouponService,
  fetchGoodwillCouponList,
  fetchYuOrderStoresService,
  rewardGoodWillCoupon,
  searchMerchantStoreService,
  searchPurchaseOrderService,
} from '../services/wholesale.service';
import { IssuedCouponData, YuorderCoupon } from '../models/yuorderCoupon.model';
import { WholesalePurchaseOrder } from '../models/wholesalePurchaseOrder.model';

import { toast } from 'react-toastify';
import { MerchantStore } from '../models/merchantStore.model';
import { WholesaleStore } from '../models/wholesaleStore.model';
import OtherYuorderCoupons from '../../pages/wholesale/otherCoupon';
import React from 'react';

export const fetchWholesalePurchaseOrderSearchRecords = createAsyncThunk(
  'wholesale/purchaseOrderSearch',
  async (payload: { displayOrderId: string; supplierCode: string }, thunkAPI) => {
    try {
      const response: any = await searchPurchaseOrderService({
        displayOrderId: payload.displayOrderId,
        supplierCode: payload.supplierCode,
      });

      if (response.isSuccessful && 'data' in response) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.message || "Couldn't fetch wholesale order.");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Couldn't fetch wholesale order.");
    }
  },
);
export const fetchGoodwillCouponRewardRecords = createAsyncThunk(
  'wholesale/goodwill-coupon-reward',
  async (payload: { purchaseOrderId: string; couponId: string; expiryDate: string }, thunkAPI) => {
    try {
      const response: any = await rewardGoodWillCoupon({
        purchaseOrderId: payload.purchaseOrderId,
        couponId: payload.couponId,
        expiryDate: payload.expiryDate,
      });

      if (response.isSuccessful && 'data' in response) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.message || "Couldn't fetch wholesale order.");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Couldn't fetch wholesale order.");
    }
  },
);
export const fetchGoodwillCouponsRecords = createAsyncThunk('wholesale/goodwill-coupon-list', async (_, thunkAPI) => {
  try {
    const response: any = await fetchGoodwillCouponList();

    if (response.isSuccessful) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.message || "Couldn't fetch goodwill coupon list");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue("Couldn't fetch goodwill coupon list");
  }
});

export const fetchYuOrderStores = createAsyncThunk('yuorder-coupons/get-yuorder-store', async (_, thunkAPI) => {
  try {
    const response: any = await fetchYuOrderStoresService();

    if (response.isSuccessful) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.message || "Couldn't fetch goodwill coupon list");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue("Couldn't fetch stores");
  }
});

export const searchMerchantStores = createAsyncThunk(
  'yuorder-coupons/search-merchant-stores',
  async (payload: { contact: string }, thunkAPI) => {
    try {
      const response: any = await searchMerchantStoreService(payload);
      console.log(response);
      if (response.isSuccessful) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.message || 'store not found');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Couldn't fetch merchant store");
    }
  },
);

export const createOtherYuorderCoupon = createAsyncThunk(
  'yuorder-coupons/create-other-coupon',
  async (
    payload: {
      couponCatalogueReference: string;
      issuerUsername: string;
      merchantStoreId: string;
      wholesaleStoreId: string;
      parentWholesaleStoreId: string;
      yuorderCouponType: string;
      amount: number;
    },
    thunkAPI,
  ) => {
    try {
      console.log(payload);
      const response: any = await createOtherYuorderCouponService(payload);

      if (response.isSuccessful) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.message || 'Coupon could not be issued');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue('Coupon issue failed');
    }
  },
);
// export const searchMerchantStore = createAsyncThunk('yuorder-coupons/search-merchant-stores', async (_, thunkAPI) => {
//   try {
//     const response: any = await fetchYuOrderStores();

//     if (response.isSuccessful && 'data' in response) {
//       return response.data;
//     } else {
//       return thunkAPI.rejectWithValue(response.message || "Couldn't fetch goodwill coupon list");
//     }
//   } catch (error) {
//     return thunkAPI.rejectWithValue("Couldn't fetch goodwill coupon list");
//   }
// });

interface WholesaleState {
  wholesalePurchaseOrderSearchData: WholesalePurchaseOrder[] | null;
  wholesalePurchaseOrderSearchLoading: boolean;
  wholesalePurchaseOrderSearchError: boolean;
  goodwillCouponsLoading: boolean;
  goodwillCouponsError: boolean;
  goodwillCouponsData: YuorderCoupon[];
  goodwillCouponRewardLoading: boolean;
  goodwillCouponRewardError: boolean;
  merchantStore: MerchantStore | null;
  merchantStoreLoading: boolean;
  merchantStoreError: boolean;
  wholesaleStores: WholesaleStore[] | null;
  wholesaleStoresLoading: boolean;
  wholesaleStoresError: boolean;
  createOtherCouponLoading: boolean;
  createOtherCouponError: boolean;
  rewardedCoupon: IssuedCouponData | null; // other yuOrder coupon
  // goodwillCouponRewardData: YuorderCoupon | null;
}
const initialState: WholesaleState = {
  wholesalePurchaseOrderSearchData: null,
  wholesalePurchaseOrderSearchLoading: false,
  wholesalePurchaseOrderSearchError: false,
  goodwillCouponsLoading: false,
  goodwillCouponsError: false,
  goodwillCouponsData: <YuorderCoupon[]>[],
  goodwillCouponRewardLoading: false,
  goodwillCouponRewardError: false,
  merchantStore: null,
  merchantStoreLoading: false,
  merchantStoreError: false,
  wholesaleStores: null,
  wholesaleStoresError: false,
  wholesaleStoresLoading: false,
  createOtherCouponError: false,
  createOtherCouponLoading: false,
  rewardedCoupon: null, // other yuOrder coupon

  // goodwillCouponRewardData: null,
};

const wholesaleSlice = createSlice({
  name: 'wholesale',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createOtherYuorderCoupon.pending, (state) => {
        state.createOtherCouponError = false;
        state.createOtherCouponLoading = true;
        state.rewardedCoupon = null;
      })
      .addCase(createOtherYuorderCoupon.fulfilled, (state, action) => {
        state.createOtherCouponError = false;
        state.createOtherCouponLoading = false;
        state.rewardedCoupon = action.payload.data;
      })
      .addCase(createOtherYuorderCoupon.rejected, (state, action) => {
        state.createOtherCouponError = true;
        state.createOtherCouponLoading = false;
        state.rewardedCoupon = null;
        toast.error('Coupon reward failed');
      })
      .addCase(fetchYuOrderStores.pending, (state) => {
        state.wholesaleStoresLoading = true;
        state.wholesaleStoresError = false;
      })
      .addCase(fetchYuOrderStores.fulfilled, (state, action) => {
        state.wholesaleStores = action.payload.data;
        state.wholesaleStoresLoading = false;
        state.wholesaleStoresError = false;
      })
      .addCase(fetchYuOrderStores.rejected, (state, action) => {
        state.wholesaleStoresLoading = false;
        state.wholesaleStoresError = true;
        toast.error('Error in YuOrder stores');
      })
      .addCase(searchMerchantStores.pending, (state) => {
        state.merchantStoreLoading = false;
        state.merchantStoreError = true;
      })
      .addCase(searchMerchantStores.fulfilled, (state, action) => {
        state.merchantStore = action.payload.data;
        console.log('data loaded successfully');
        console.log(state.merchantStore);
        state.merchantStoreLoading = false;
        state.merchantStoreError = true;
        toast.success('Merchant store found');
      })
      .addCase(searchMerchantStores.rejected, (state, action) => {
        state.merchantStoreLoading = false;
        state.merchantStoreError = true;
        toast.error('Merchant store not found');
      })
      .addCase(fetchWholesalePurchaseOrderSearchRecords.pending, (state) => {
        state.wholesalePurchaseOrderSearchLoading = true;
        state.wholesalePurchaseOrderSearchError = false;
      })
      .addCase(fetchWholesalePurchaseOrderSearchRecords.fulfilled, (state, action) => {
        state.wholesalePurchaseOrderSearchLoading = false;
        state.wholesalePurchaseOrderSearchData = action.payload.data;
        state.wholesalePurchaseOrderSearchError = false;
      })
      .addCase(fetchWholesalePurchaseOrderSearchRecords.rejected, (state, action) => {
        state.wholesalePurchaseOrderSearchLoading = false;
        state.wholesalePurchaseOrderSearchError = true;
        toast.error('Error in fetching Order');
      })
      .addCase(fetchGoodwillCouponsRecords.pending, (state) => {
        state.goodwillCouponsLoading = true;
        state.goodwillCouponsError = false;
      })
      .addCase(fetchGoodwillCouponsRecords.fulfilled, (state, action) => {
        state.goodwillCouponsLoading = false;
        state.goodwillCouponsData = action.payload.data;

        state.goodwillCouponsError = false;
      })
      .addCase(fetchGoodwillCouponsRecords.rejected, (state, action) => {
        state.goodwillCouponsLoading = false;
        state.goodwillCouponsError = true;
        toast.error('Error fetching Goodwill Coupon');
      })
      .addCase(fetchGoodwillCouponRewardRecords.pending, (state) => {
        state.goodwillCouponRewardLoading = true;
        state.goodwillCouponRewardError = false;
      })
      .addCase(fetchGoodwillCouponRewardRecords.fulfilled, (state, action) => {
        state.goodwillCouponRewardLoading = false;
        // state.goodwillCouponRewardData = action.payload.data;
        state.goodwillCouponRewardError = false;
        toast.success(action.payload.message);
      })
      .addCase(fetchGoodwillCouponRewardRecords.rejected, (state, action: any) => {
        console.log(action.payload);
        state.goodwillCouponRewardLoading = false;
        state.goodwillCouponRewardError = true;
        toast.error(action.payload.response.data.message);
      });
  },
});

export default wholesaleSlice.reducer;
