import React, { useEffect, useState } from 'react';
import { Card, Form, Input, Button, Select, Radio, Space, InputNumber, Slider, Row, Col, message } from 'antd';
import {
  fetchYuOrderStores,
  searchMerchantStores as searchMerchantStore,
  createOtherYuorderCoupon,
} from '../../data/reducers/wholesale.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../data/reducers';
import { WholesaleStore } from '../../data/models/wholesaleStore.model';
import { YuorderCoupon } from '../../data/models/yuorderCoupon.model';
import { AppDispatch } from '../../data/storeConfig';
import { toast, ToastContainer } from 'react-toastify';
import { CouponToast } from '../../shared/common/CouponToast';
import { useMsal } from '@azure/msal-react';

const OtherYuorderCoupons = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedCouponId, setSelectedCouponId] = useState('');
  const [selectedCustomCoupon, setSelectedCustomCoupon] = useState<YuorderCoupon | null>(null);
  const [contact, setContact] = useState('');
  const [couponAmount, setCouponAmount] = useState<number | null>(null);
  const [selectedStore, setSelectedStore] = useState<WholesaleStore | null>(null);
  const [hasSearched, setHasSearched] = useState(false);
  const {accounts} = useMsal();

  const { merchantStore, goodwillCouponsData, wholesaleStores, rewardedCoupon, createOtherCouponLoading } = useSelector(
    (state: RootState) => state.wholesale,
  );

  useEffect(() => {
    if (rewardedCoupon) {
      toast.success(<CouponToast {...rewardedCoupon} />, {
        position: 'bottom-center',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [createOtherCouponLoading, rewardedCoupon]);

  const handleSearch = () => {
    if (contact.trim().length !== 10) {
      message.warning('Please enter a 10 digit contact number');
      return;
    }
    dispatch(searchMerchantStore({ contact }) as any);
    dispatch(fetchYuOrderStores() as any);
    setHasSearched(true);
  };

  const handleCouponChange = (value: string) => {
    setSelectedCouponId(value);
    const coupon = goodwillCouponsData.find((c) => c.id === value);
    setSelectedCustomCoupon(coupon || null);
    setCouponAmount(coupon ? coupon.minIssueValue : null);
  };

  const handleAmountChanged = (value: number | null) => {
    if (!selectedCustomCoupon) {
      message.warning('Please select coupon type');
      return;
    }
    if (value === null) return;
    if (value < (selectedCustomCoupon.minIssueValue ?? 0) || value > (selectedCustomCoupon.maxIssueValue ?? 0)) {
      message.warning(
        `Coupon value must be between ₱${selectedCustomCoupon.minIssueValue} and ₱${selectedCustomCoupon.maxIssueValue}`,
      );
      return;
    }
    setCouponAmount(value);
  };

  const handleRewardCoupon = () => {
    if (!merchantStore?.merchantStoreId) {
      message.warning('Search merchant store');
      return;
    }
    if (!wholesaleStores || wholesaleStores.length === 0) {
      message.warning('Yuorder store not found');
      return;
    }
    if (!selectedStore) {
      message.warning('Please select a Yuorder store');
      return;
    }
    if (!selectedCouponId || !selectedCustomCoupon) {
      message.warning('Please select coupon type');
      return;
    }
    if (
      couponAmount === null ||
      couponAmount < (selectedCustomCoupon.minIssueValue ?? 0) ||
      couponAmount > (selectedCustomCoupon.maxIssueValue ?? 0)
    ) {
      message.warning(
        `Amount should be between ₱${selectedCustomCoupon.minIssueValue} and ₱${selectedCustomCoupon.maxIssueValue}`,
      );
      return;
    }
    dispatch(
      createOtherYuorderCoupon({
        couponCatalogueReference: selectedCustomCoupon.id,
        issuerUsername: accounts[0].username,
        merchantStoreId: merchantStore.merchantStoreId,
        wholesaleStoreId: selectedStore.merchantStoreId,
        parentWholesaleStoreId: selectedStore.parentStoreId,
        yuorderCouponType: selectedCustomCoupon.title ?? 'NA',
        amount: couponAmount,
      }) as any,
    );
  };

  return (
    <Card title="Other Yuorder Coupons" style={{ maxWidth: 600, margin: 'auto' }}>
      <Form layout="vertical">
        <Form.Item label="Search Merchant by Contact Number">
          <Space>
            <Input
              placeholder="Enter contact number"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
              maxLength={10}
            />
            <Button type="primary" onClick={handleSearch}>
              Search
            </Button>
          </Space>
        </Form.Item>

        {merchantStore && (
          <Form.Item label="Merchant Store">
            <p>{merchantStore.storeName || 'No store selected'}</p>
          </Form.Item>
        )}

        {wholesaleStores && wholesaleStores.length > 0 && (
          <Form.Item label={`Select Yuorder Store:`}>
            <Radio.Group
              onChange={(e) => {
                const store = wholesaleStores.find((store) => store.merchantStoreId === e.target.value);
                setSelectedStore(store || null);
              }}
              value={selectedStore?.merchantStoreId}
            >
              <Space direction="vertical">
                {wholesaleStores.map((store) => (
                  <Radio key={store.merchantStoreId} value={store.merchantStoreId}>
                    {store.storeName}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        )}

        <Form.Item label="Select a Coupon Type">
          <Select
            placeholder="Select a coupon type"
            value={selectedCouponId || undefined}
            onChange={handleCouponChange}
          >
            {goodwillCouponsData
              .filter((coupon) => coupon.couponType === 'CUSTOM_VALUE_COUPON')
              .map((coupon) => (
                <Select.Option key={coupon.id} value={coupon.id}>
                  {coupon.title}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        {selectedCustomCoupon && (
          <Form.Item
            label={`Enter Coupon Amount (₱${selectedCustomCoupon.minIssueValue} - ₱${selectedCustomCoupon.maxIssueValue})`}
          >
            <Row>
              <Col span={16}>
                <Slider
                  min={selectedCustomCoupon.minIssueValue ?? 0}
                  max={selectedCustomCoupon.maxIssueValue ?? 0}
                  onChange={handleAmountChanged}
                  step={10}
                  value={typeof couponAmount === 'number' ? couponAmount : selectedCustomCoupon.minIssueValue ?? 0}
                />
              </Col>
              <Col span={8}>
                <InputNumber
                  style={{ marginLeft: 16 }}
                  min={selectedCustomCoupon.minIssueValue ?? 0}
                  max={selectedCustomCoupon.maxIssueValue ?? 0}
                  step={10}
                  value={couponAmount || selectedCustomCoupon.minIssueValue}
                  onChange={handleAmountChanged}
                />
              </Col>
            </Row>
          </Form.Item>
        )}

        <Form.Item>
          <Button type="primary" onClick={handleRewardCoupon} loading={createOtherCouponLoading}>
            Reward Coupon
          </Button>
        </Form.Item>
      </Form>
      <ToastContainer/>
    </Card>
  );
};

export default OtherYuorderCoupons;
