// src/components/ReportView.js
import React, { useState } from 'react';
import { DatePicker, Button, Table, Select } from 'antd';
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../data/storeConfig';
import * as dashboardActions from '../../data/reducers/dashboard.reducer';
import { RootState } from '../../data/reducers';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { useNavigate } from 'react-router';

const { RangePicker } = DatePicker;

interface FSASalesReport {
  total: number;
  subTotal: number;
  fulfilmentType: string;
  orderStatus: string;
  paymentMode: string;
  deliveryCharges: number;
  displayOrderId: string;
  completionTimestamp: string;
  isORRequired: boolean;
  isAssistedOrder: boolean;
  ownerName: string;
  merchantStoreName: string;
  orderingAccountOwnerName: string;
  orderingStoreName: string;
  ambassadorReferralId: string;
}

const ReportView = () => {
  const navigate = useNavigate();
  const [dates, setDates] = useState<Moment[]>([]);
  const [data, setData] = useState<any>([]);
  const dispatch = useDispatch<AppDispatch>();
  const { YuOrderFSASalesReport, YuOrderFSASalesReportError, YuOrderFSASalesReportLoading } = useSelector(
    (state: RootState) => state.dashboard,
  );
  const [supplierCode, setSupplierCode] = useState<string>('');

  const fetchData = (supplierCode: string, startDate: Date, endDate: Date) => {
    dispatch(dashboardActions.fetchYuOrderFSASalesReport({ supplierCode, startDate, endDate }));
  };

  const handleDateChange = (dates: RangeValue<Moment>, formatString: [string, string]) => {
    if (dates && dates[0] && dates[1]) {
      setDates(dates as Moment[]);
      if (supplierCode.length > 0) {
        fetchData(supplierCode, dates[0].toDate(), dates[1].toDate());
      }
    }
  };

  const handleSupplierChange = (value: string) => {
    setSupplierCode(value);
    if (dates.length === 2) {
      fetchData(value, dates[0].toDate(), dates[1].toDate());
    }
  };

  const exportToExcel = () => {
    // Define the columns to export
    const columnsToExport: (keyof FSASalesReport)[] = [
      'displayOrderId',
      'fulfilmentType',
      'completionTimestamp',
      'isORRequired',
      'orderingStoreName',
      'orderingAccountOwnerName',
      'ownerName',
      'merchantStoreName',
      'paymentMode',
      'orderStatus',
      'deliveryCharges',
      'subTotal',
      'total',
      'ambassadorReferralId',
    ];

    // Filter data to include only the columns to export
    const filteredData = YuOrderFSASalesReport.map((item: any) =>
      columnsToExport.reduce((acc: any, column: any) => {
        if (column == 'subTotal' || column == 'total') {
          acc[column] = item[column].toFixed(2);
        } else {
          acc[column] = item[column];
        }
        return acc;
      }, {} as Partial<FSASalesReport>),
    );

    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
    if (dates.length === 2) {
      XLSX.writeFile(
        workbook,
        `report_${moment(dates[0]).format('YYYYMMDD')}_${moment(dates[1]).format('YYYYMMDD')}.xlsx`,
      );
    }
  };

  const columns = [
    { title: 'Display Order ID', dataIndex: 'displayOrderId', key: 'displayOrderId' },
    { title: 'Fulfilment Type', dataIndex: 'fulfilmentType', key: 'fulfilmentType' },
    { title: 'Completion Timestamp', dataIndex: 'completionTimestamp', key: 'completionTimestamp', render: (value: Date) => new Date(value).toLocaleString().toUpperCase() },
    {
      title: 'OR Required',
      dataIndex: 'isORRequired',
      key: 'isORRequired',
      render: (value: boolean) => (value ? 'Yes' : 'No'),
    },{
      title: 'Is Assisted',
      dataIndex: 'isAssistedOrder',
      key: 'isAssistedOrder',
      render: (value: boolean) => (value ? 'Yes' : 'No'),
    },
    { title: 'Owner Name', dataIndex: 'ownerName', key: 'ownerName' },
    { title: 'Merchant Store Name', dataIndex: 'merchantStoreName', key: 'merchantStoreName' },
    { title: 'Ordering Account Name', dataIndex: 'orderingAccountOwnerName', key: 'orderingAccountOwnerName' },
    { title: 'Ordering Store Name', dataIndex: 'orderingStoreName', key: 'orderingStoreName' },
    { title: 'Payment Mode', dataIndex: 'paymentMode', key: 'paymentMode' },
    { title: 'Order Status', dataIndex: 'orderStatus', key: 'orderStatus' },
    { title: 'Delivery Charges', dataIndex: 'deliveryCharges', key: 'deliveryCharges' },
    { title: 'Sub Total', dataIndex: 'subTotal', key: 'subTotal', render: (value: number) => value.toFixed(2) },
    { title: 'Total', dataIndex: 'total', key: 'total', render: (value: number) => value.toFixed(2) },
    { title: 'Ambassador Referral ID', dataIndex: 'ambassadorReferralId', key: 'ambassadorReferralId' },
  ];

  return (
    <div>
      <h1>YuOrder FSA Sales Report</h1>
      <Select onChange={handleSupplierChange} placeholder="Select Supplier">
        <Select.Option value="LFL-PH">Lots for Less</Select.Option>
      </Select>
      <RangePicker onChange={handleDateChange} style={{ marginLeft: 8 }} />
      <Button onClick={exportToExcel} disabled={!YuOrderFSASalesReport.length} style={{ marginLeft: 8 }}>
        Export to Excel
      </Button>
      <Button onClick={() => navigate('/reports/fsa-yuOrder-search')} style={{ marginLeft: 8 }}>
        Search Order
      </Button>
      <Table columns={columns} dataSource={YuOrderFSASalesReport} style={{ marginTop: 16 }} scroll={{ x: 1200 }} />
    </div>
  );
};

export default ReportView;
