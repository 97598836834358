import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getFSAMerchantsService,
  getFSAsService,
  searchMerchantsByFSACodeOrPhoneService,
  unassignFSAMerchantService,
  updateFSAMerchantAssignmentService,
} from '../services/fsamanagement.service';
import { FSA } from '../models/reportMerchant.model';
import { MerchantStore } from '../models/reportMerchant.model';
import { toast } from 'react-toastify';

export const GetFSAsList = createAsyncThunk('store/fetchFSAs', async (params: null, { rejectWithValue }) => {
  try {
    const response = await getFSAsService();
    return response;
  } catch (error) {
    return rejectWithValue('Failed to fetch FSAs');
  }
});

export const GetFSAMerchants = createAsyncThunk(
  'store/fetchFSAMerchants',
  async (params: { fsaCode: string }, { rejectWithValue }) => {
    try {
      const response = await getFSAMerchantsService(params.fsaCode);
      return response;
    } catch (error) {
      return rejectWithValue('Failed to fetch FSA assigned merchants');
    }
  },
);

export const SearchMerchantsByFSACodeOrPhone = createAsyncThunk(
  'store/searchMerchantsByFSACodeOrPhone',
  async (params: { searchQuery: string }, { rejectWithValue }) => {
    try {
      const response = await searchMerchantsByFSACodeOrPhoneService(params.searchQuery);
      return response;
    } catch (error) {
      return rejectWithValue('Failed to search merchants');
    }
  },
);

export type AssignFSAMerchantParams = {
  fsaCode: string;
  merchantStoreIds: string[];
};

export const UpdateFSAMerchantAssignment = createAsyncThunk(
  'store/updateFSAMerchantAssignment',
  async (params: AssignFSAMerchantParams, { rejectWithValue }) => {
    try {
      const response = await updateFSAMerchantAssignmentService(params);
      return response;
    } catch (error) {
      return rejectWithValue('Failed to update FSA merchant assignments');
    }
  },
);

export type UnassignFSAMerchantParams = {
  merchantStoreId: string;
};

export const UnassignFSAMerchant = createAsyncThunk(
  'store/unassignFSAMerchant',
  async (params: UnassignFSAMerchantParams, { rejectWithValue }) => {
    try {
      const response = await unassignFSAMerchantService(params);
      return response;
    } catch (error) {
      return rejectWithValue('Failed to remove FSA merchant assignment');
    }
  },
);

const fsaManagementSlice = createSlice({
  name: 'fsaManagement',
  initialState: {
    fsaMerchants: <MerchantStore[]>[],
    fsaList: <FSA[]>[],
    merchantSearchResult: <MerchantStore[]>[],
    isFsaListLoading: false,
    isFsaListError: false,
    fsaListError: '',
    isFsaMerchantsLoading: false,
    isFsaMerchantsError: false,
    fsaMerchantsError: '',
    isMerchantsSearchLoading: false,
    isMerchantsSearchError: false,
    merchantsSearchError: '',
  },
  reducers: {},
  extraReducers: {
    [GetFSAsList.pending.type]: (state, action) => {
      state.isFsaListLoading = true;
      state.isFsaListError = false;
    },
    [GetFSAsList.fulfilled.type]: (state, action) => {
      state.isFsaListLoading = false;
      state.isFsaListError = false;
      state.fsaList = action.payload.data.data;
    },
    [GetFSAsList.rejected.type]: (state, action) => {
      state.isFsaListLoading = false;
      state.isFsaListError = true;
      state.fsaListError = action.payload;
    },
    [GetFSAMerchants.pending.type]: (state, action) => {
      state.isFsaMerchantsLoading = true;
      state.isFsaMerchantsError = false;
    },
    [GetFSAMerchants.fulfilled.type]: (state, action) => {
      state.isFsaMerchantsLoading = false;
      state.isFsaMerchantsError = false;
      state.fsaMerchants = action.payload.data.data;
    },
    [GetFSAMerchants.rejected.type]: (state, action) => {
      state.isFsaMerchantsLoading = false;
      state.isFsaMerchantsError = true;
      state.fsaMerchantsError = action.payload;
    },
    [SearchMerchantsByFSACodeOrPhone.pending.type]: (state, action) => {
      state.isMerchantsSearchLoading = true;
      state.isMerchantsSearchError = false;
    },
    [SearchMerchantsByFSACodeOrPhone.fulfilled.type]: (state, action) => {
      state.isMerchantsSearchLoading = false;
      state.isMerchantsSearchError = false;
      state.merchantSearchResult = action.payload.data.data;
    },
    [SearchMerchantsByFSACodeOrPhone.rejected.type]: (state, action) => {
      state.isMerchantsSearchLoading = false;
      state.isMerchantsSearchError = true;
      state.merchantsSearchError = action.payload;
    },
    [UpdateFSAMerchantAssignment.pending.type]: (state, action) => {
      state.isFsaMerchantsLoading = true;
      state.isFsaMerchantsError = false;
    },
    [UpdateFSAMerchantAssignment.fulfilled.type]: (state, action) => {
      state.isFsaMerchantsLoading = false;
      state.isFsaMerchantsError = false;
      toast.success('FSA merchant assignment updated successfully');
    },
    [UpdateFSAMerchantAssignment.rejected.type]: (state, action) => {
      state.isFsaMerchantsLoading = false;
      state.isFsaMerchantsError = true;
      state.fsaMerchantsError = action.payload;
    },
    [UnassignFSAMerchant.pending.type]: (state, action) => {
      state.isFsaMerchantsLoading = true;
      state.isFsaMerchantsError = false;
    },
    [UnassignFSAMerchant.fulfilled.type]: (state, action) => {
      state.isFsaMerchantsLoading = false;
      state.isFsaMerchantsError = false;
      toast.success('Merchant unassigned from FSA successfully');
    },
    [UnassignFSAMerchant.rejected.type]: (state, action) => {
      state.isFsaMerchantsLoading = false;
      state.isFsaMerchantsError = true;
      state.fsaMerchantsError = action.payload;
    },
  },
});

export default fsaManagementSlice.reducer;
