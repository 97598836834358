import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Button, DatePicker } from 'antd';
import 'antd/dist/antd.css';
import { WholesalePurchaseOrder } from '../../data/models/wholesalePurchaseOrder.model';
import { YuorderCoupon } from '../../data/models/yuorderCoupon.model';
import { RootState } from '../../data/reducers';
import {
  fetchGoodwillCouponRewardRecords,
  fetchGoodwillCouponsRecords,
  fetchWholesalePurchaseOrderSearchRecords,
} from '../../data/reducers/wholesale.reducer';
import './GoodwillCoupon.css';
import WholesalePurchaseOrderCard from './wholesalePurchaseOrderCard';
import { useNavigate } from 'react-router';
const GoodwillCoupon = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchGoodwillCouponsRecords());
  }, [dispatch]);

  const [displayOrderId, setDisplayOrderId] = useState('');
  const [selectedCouponId, setSelectedCouponId] = useState<string>('');
  const [selectPurchaseOrderId, setSelectedOrderId] = useState<string>('');
  const [couponExpiryDate, setCouponExpiryDate] = useState<Date | null>(null);

  const {
    wholesalePurchaseOrderSearchData,
    wholesalePurchaseOrderSearchLoading,
    wholesalePurchaseOrderSearchError,
    goodwillCouponsData,
    goodwillCouponsLoading,
    goodwillCouponsError,
    goodwillCouponRewardError,
    goodwillCouponRewardLoading,
  } = useSelector((state: RootState) => state.wholesale);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(fetchWholesalePurchaseOrderSearchRecords({ displayOrderId, supplierCode: 'LFL-PH' }));
  };

  const handleCheckboxChange = (couponId: string) => {
    if (selectPurchaseOrderId == '') {
      toast.error('No order selected');
    } else {
      setSelectedCouponId(couponId);
    }
  };

  const handleOrderSelection = (purchaseOrderId: string) => {
    setSelectedOrderId(purchaseOrderId);
  };

  const handleAssignCoupon = () => {
    if (selectPurchaseOrderId != '' && selectedCouponId.length > 0 && couponExpiryDate) {
      dispatch(
        fetchGoodwillCouponRewardRecords({
          purchaseOrderId: selectPurchaseOrderId,
          couponId: selectedCouponId,
          expiryDate: couponExpiryDate.toISOString(),
        }),
      );
      console.log('Assigned Coupons:', selectedCouponId, 'Expiry Date:', couponExpiryDate);
    } else {
      toast.error('Please select an order, a coupon, and set an expiry date.');
    }
  };

  return (
    <div className="container" style={{ maxWidth: 600, margin: 'auto' }}>
      <ToastContainer position="bottom-center" />
      <div className="main-content">
        <div>
          <Button type="primary" title="Other Coupons" onClick={() => navigate('../yuorder/other-coupons')}>
            Other Coupons
          </Button>
        </div>
        <div className="form-container">
          <h1 className="title">Goodwill Coupon Search</h1>
          <form className="form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Display Order ID:</label>
              <input
                type="text"
                value={displayOrderId}
                onChange={(e) => setDisplayOrderId(e.target.value)}
                className="input"
                required
              />
            </div>
            <button type="submit" className="button assign-button" disabled={wholesalePurchaseOrderSearchLoading}>
              {wholesalePurchaseOrderSearchLoading ? 'Searching...' : 'Search'}
            </button>
          </form>
          {wholesalePurchaseOrderSearchError && <p className="error">Error fetching data.</p>}
          {wholesalePurchaseOrderSearchData && Array.isArray(wholesalePurchaseOrderSearchData) && (
            <div>
              <h1>Select Order to reward Coupon</h1>
              <ul
                style={{
                  listStyleType: 'none',
                  padding: 0,
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                  gap: '10px',
                }}
              >
                {wholesalePurchaseOrderSearchData?.map(
                  (value: WholesalePurchaseOrder, index: number, array: WholesalePurchaseOrder[]) => (
                    <WholesalePurchaseOrderCard
                      key={index}
                      order={value}
                      selectPurchaseOrderId={selectPurchaseOrderId}
                      handleOrderSelection={handleOrderSelection}
                    />
                  ),
                )}
              </ul>
            </div>
          )}
        </div>
        {
          <div className="">
            <h3 style={{ paddingTop: '20px' }}>Select Goodwill Coupon</h3>
            <div style={{ paddingBottom: '20px', fontFamily: 'Arial, sans-serif' }}>
              {goodwillCouponsLoading && <p>Loading coupons...</p>}
              {goodwillCouponsError && <p>Error fetching coupons.</p>}

              <ul
                style={{
                  listStyleType: 'none',
                  padding: 0,
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  gap: '10px',
                  width: '95%',
                }}
              >
                {goodwillCouponsData &&
                  Array.isArray(goodwillCouponsData) &&
                  goodwillCouponsData!
                    .filter((value: YuorderCoupon) => value.couponType === 'GOODWILL_COUPON')
                    .map((value: YuorderCoupon, index: number, array: YuorderCoupon[]) => (
                      <li
                        key={index}
                        style={{
                          cursor: 'pointer',
                          padding: '10px',
                          marginBottom: '10px',
                          border: '1px solid #007BFF',
                          borderRadius: '5px',
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                          backgroundColor: '#f8f9fa',
                          alignItems: 'center',
                        }}
                        onClick={() => handleCheckboxChange(value.id)}
                      >
                        <div
                          style={{
                            display: 'flex',
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={selectedCouponId == value.id}
                            onChange={() => handleCheckboxChange(value.id)}
                            style={{ marginRight: '10px', pointerEvents: 'none' }}
                          />
                          {value.title} ({value.maxDiscountAmount} ₱)
                        </div>

                        <div>{value.description}</div>
                      </li>
                    ))}
              </ul>
            </div>

            {goodwillCouponsData && Array.isArray(goodwillCouponsData) && goodwillCouponsData?.length > 0 && (
              <>
                <div style={{ marginBottom: '20px' }}>
                  <label>Set Expiry Date:</label>
                  <DatePicker
                    showTime
                    onChange={(value) => setCouponExpiryDate(value ? value.toDate() : null)}
                    style={{ width: '100%', marginTop: '10px' }}
                  />
                </div>
                <button
                  onClick={handleAssignCoupon}
                  className="button assign-button"
                  disabled={goodwillCouponRewardLoading}
                >
                  {goodwillCouponRewardLoading ? 'Loading' : 'Assign Coupon'}
                </button>
              </>
            )}
          </div>
        }
      </div>
    </div>
  );
};

export default GoodwillCoupon;
