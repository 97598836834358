import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { BuildOutlined, UserOutlined, TableOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useLocation
import { GiftCard16Filled, Grid16Regular } from '@fluentui/react-icons';
import { ToastContainer } from 'react-toastify';

const { Sider } = Layout;

const NavigationShell: React.FC = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation(); // Get the current route

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  // Determine the selected key based on the current route
  let selectedKey = '1';
  const pathname = location.pathname.split('/').length > 0 ? location.pathname.split('/')[1] : 'dashboard';
  if (pathname === 'dashboard') {
    selectedKey = '1';
  } else if (pathname === 'profile') {
    selectedKey = '2';
  } else if (pathname === 'tutorials') {
    selectedKey = '3';
  } else if (pathname === 'tipsAndTricks') {
    selectedKey = '4';
  } else if (pathname === 'reports/fsa-yuOrder-sales') {
    selectedKey = '5';
  } else if (pathname === 'reports/fsa-signups') {
    selectedKey = '6';
  } else if (pathname === 'reports/yuSell-sales') {
    selectedKey = '7';
  } else if (pathname === 'wholesale/goodwill-coupon') {
    selectedKey = '8';
  } else if (pathname === 'fsa-management') {
    selectedKey = '9';
  }

  return (
    <Sider theme="light" collapsedWidth={70} collapsible collapsed={collapsed} onCollapse={toggleCollapsed}>
      <div className="logo" style={{ padding: 12 }}>
        <img src="https://yufin.ph/wp-content/uploads/2024/07/yufin-Logotype-Black.png" height={50} />
      </div>
      <Menu
        theme="light"
        selectedKeys={[selectedKey]} // Set the selected key dynamically
        mode="inline"
      >
        <Menu.Item key="1" icon={<Grid16Regular />} onClick={() => navigate('/')}>
          Dashboard
        </Menu.Item>
        <Menu.Item key="2" icon={<UserOutlined />} onClick={() => navigate('/profile')}>
          Profile
        </Menu.Item>
        <Menu.Item key="3" icon={<BuildOutlined />} onClick={() => navigate('/tutorials')}>
          Tutorials
        </Menu.Item>
        <Menu.Item key="4" icon={<BuildOutlined />} onClick={() => navigate('/tipsAndTricks')}>
          Tips and Tricks
        </Menu.Item>
        <Menu.Item key="5" icon={<TableOutlined />} onClick={() => navigate('/reports/fsa-yuOrder-sales')}>
          yuOrder FSA Report
        </Menu.Item>
        <Menu.Item key="6" icon={<TableOutlined />} onClick={() => navigate('/reports/fsa-signups')}>
          FSA signups Report
        </Menu.Item>
        <Menu.Item key="7" icon={<TableOutlined />} onClick={() => navigate('/reports/yuSell-sales')}>
          YuSell Sales
        </Menu.Item>
        <Menu.Item key="8" icon={<GiftCard16Filled />} onClick={() => navigate('/wholesale/goodwill-coupon')}>
          Yuorder Coupons
        </Menu.Item>
        <Menu.Item key="9" icon={<UsergroupAddOutlined />} onClick={() => navigate('/fsa-management')}>
          FSA Management
        </Menu.Item>
      </Menu>
      <div>
        <ToastContainer />
      </div>
    </Sider>
  );
};

export default NavigationShell;
