import { constants } from '../../config';
import { YuorderCoupon } from '../models/yuorderCoupon.model';
import { get, post } from './services.common';

export const searchPurchaseOrderService = async (data: { displayOrderId: string; supplierCode: string }) => {
  const url =
    constants.endpoint +
    `/store/WholesalePurchaseOrder/admin/coupon-eligible-search?key=W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo&displayOrderId=${data.displayOrderId}`;
  const response = await get(url, {});
  return response;
};

export const fetchGoodwillCouponList = async () => {
  const url =
    constants.endpoint +
    `/store/YuOrderCoupons/goodwillCoupon-list?key=W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo`;
  const response = await get(url, {});
  return response;
};

export const fetchYuOrderStoresService = async () => {
  const url =
    constants.endpoint +
    `/store/YuOrderCoupons/get-yuorder-store?key=W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo`;
  const response = await get(url, {});
  return response;
};

export const searchMerchantStoreService = async (data: { contact: string }) => {
  const url =
    constants.endpoint +
    `/store/YuOrderCoupons/search-merchant-store?key=W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo&contact=${data.contact}`;
  const response = await get(url, {});
  return response;
};

export const createOtherYuorderCouponService = async (data: {
  couponCatalogueReference: string;
  issuerUsername: string;
  merchantStoreId: string;
  wholesaleStoreId: string;
  parentWholesaleStoreId: string;
  yuorderCouponType: string;
  amount: number;
}) => {
  const url =
    constants.endpoint +
    `/store/YuOrderCoupons/add-other-yuorder-coupon?key=W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo`;
  const response = await post(url, data, {});
  return response;
};
// export const rewardGoodWillCoupon = async (data: { couponId: string; purchaseOrderId: string }) => {
export const rewardGoodWillCoupon = async (data: { couponId: string; purchaseOrderId: string, expiryDate: string }) => {
  const url =
    constants.endpoint +
    `/store/YuOrderCoupons/reward-goodwill-coupon?key=W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo&purchaseOrderId=${data.purchaseOrderId}&couponId=${data.couponId}&expiryTimestamp=${data.expiryDate}`;
  const response = await get(url, {});
  return response;
};
