// src/components/ReportView.js
import React, { useState } from 'react';
import { DatePicker, Button, Table, Select, Input } from 'antd';
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../data/storeConfig';
import * as dashboardActions from '../../data/reducers/dashboard.reducer';
import { RootState } from '../../data/reducers';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';

const { RangePicker } = DatePicker;

interface PurchaseOrderSearchItem {
  total: number;
  subTotal: number;
  fulfilmentType: string;
  orderStatus: string;
  paymentMode: string;
  deliveryCharges: number;
  displayOrderId: string;
  completionTimestamp: string;
  isORRequired: boolean;
  isAssistedOrder: boolean;
  ownerName: string;
  merchantStoreName: string;
  orderingAccountOwnerName: string;
  orderingStoreName: string;
  ambassadorReferralId: string;
}

const OrderSearch = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const dispatch = useDispatch<AppDispatch>();
  const { YuOrderFSASalesOrders, YuOrderFSASalesOrdersError, YuOrderFSASalesOrdersLoading } = useSelector(
    (state: RootState) => state.dashboard,
  );
  const [supplierCode, setSupplierCode] = useState<string>('');

  const fetchData = (supplierCode: string, searchQuery: string) => {
    dispatch(dashboardActions.fetchYuOrderOrderForFSA({ supplierCode, searchQuery }));
  };

  const handleQueryChange = (e: any) => {
    setSearchQuery(e.target.value);
    debugger
    if (supplierCode != '' && e.target.value.length > 2) {
      debugger
      if (e.target.value.length > 2) {
        debugger
        fetchData(supplierCode, e.target.value);
      }
    }
  };

  const handleSupplierChange = (value: string) => {
    setSupplierCode(value);
    if (searchQuery != '') {
      if (searchQuery.length > 2) {
        fetchData(value, searchQuery);
      }
    }
  };

  const columns = [
    { title: 'Display Order ID', dataIndex: 'displayOrderId', key: 'displayOrderId' },
    { title: 'Fulfilment Type', dataIndex: 'fulfilmentType', key: 'fulfilmentType' },
    { title: 'Completion Timestamp', dataIndex: 'completionTimestamp', key: 'completionTimestamp' },
    {
      title: 'OR Required',
      dataIndex: 'isORRequired',
      key: 'isORRequired',
      render: (value: boolean) => (value ? 'Yes' : 'No'),
    },{
      title: 'Is Assisted',
      dataIndex: 'isAssistedOrder',
      key: 'isAssistedOrder',
      render: (value: boolean) => (value ? 'Yes' : 'No'),
    },
    { title: 'Owner Name', dataIndex: 'ownerName', key: 'ownerName' },
    { title: 'Merchant Store Name', dataIndex: 'merchantStoreName', key: 'merchantStoreName' },
    { title: 'Ordering Account Name', dataIndex: 'orderingAccountOwnerName', key: 'orderingAccountOwnerName' },
    { title: 'Ordering Store Name', dataIndex: 'orderingStoreName', key: 'orderingStoreName' },
    { title: 'Payment Mode', dataIndex: 'paymentMode', key: 'paymentMode' },
    { title: 'Order Status', dataIndex: 'orderStatus', key: 'orderStatus' },
    { title: 'Delivery Charges', dataIndex: 'deliveryCharges', key: 'deliveryCharges' },
    { title: 'Sub Total', dataIndex: 'subTotal', key: 'subTotal', render: (value: number) => value.toFixed(2) },
    { title: 'Total', dataIndex: 'total', key: 'total', render: (value: number) => value.toFixed(2) },
    { title: 'Ambassador Referral ID', dataIndex: 'ambassadorReferralId', key: 'ambassadorReferralId' },
  ];

  return (
    <div>
      <h1>YuOrder Search</h1>
      <Select onChange={handleSupplierChange} placeholder="Select Supplier">
        <Select.Option value="LFL-PH">Lots for Less</Select.Option>
      </Select>
      <Input
        placeholder="Search"
        value={searchQuery}
        onChange={handleQueryChange}
        style={{ marginLeft: 8, display: 'inline-block', width: 250 }}
      />

      <Table columns={columns} dataSource={YuOrderFSASalesOrders} style={{ marginTop: 16 }} scroll={{ x: 1200 }} />
    </div>
  );
};

export default OrderSearch;
