import { constants } from '../../config';
import { get, post } from './services.common';
import { AssignFSAMerchantParams, UnassignFSAMerchantParams } from '../reducers/fsamanagement.reducer';

export const getFSAsService = async () => {
  const url = `${constants.endpoint}/store/FSAManagement/fsa/list`;
  const response = await get(url, {
    key: 'W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo',
  });
  return response;
};

export const getFSAMerchantsService = async (fsaCode: string) => {
  const url = `${constants.endpoint}/store/FSAManagement/stores/by-fsa?fsaCode=${fsaCode}`;
  const response = await get(url, {
    key: 'W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo',
  });
  return response;
};

export const searchMerchantsByFSACodeOrPhoneService = async (searchQuery: string) => {
  const url = `${constants.endpoint}/store/FSAManagement/stores/by-phone-or-fsa?searchQuery=${searchQuery}`;
  const response = await get(url, {
    key: 'W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo',
  });
  return response;
};

export const updateFSAMerchantAssignmentService = async (data: AssignFSAMerchantParams) => {
  const url = `${constants.endpoint}/store/FSAManagement/update-assignment`;
  const response = await post(
    url,
    {
      fsaCode: data.fsaCode,
      merchantStoreIds: data.merchantStoreIds,
    },

    {
      key: 'W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo',
    },
  );
  return response;
};

export const unassignFSAMerchantService = async (data: UnassignFSAMerchantParams) => {
  const url = `${constants.endpoint}/store/FSAManagement/remove-assignment?merchantStoreId=${data.merchantStoreId}`;
  const response = await post(
    url,
    {},
    {
      key: 'W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo',
    },
  );
  return response;
};
