import React, { useEffect, useState } from 'react';
import { Layout, List, Card, Button, Modal, Input, Space, Popconfirm, Spin } from 'antd';
import { PlusOutlined, SearchOutlined, DeleteOutlined, UserAddOutlined, UserDeleteOutlined } from '@ant-design/icons';
import { MerchantStore, MerchantStoreData, MerchantStoreResponse } from '../../data/models/reportMerchant.model';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../data/reducers';
import { AppDispatch } from '../../data/storeConfig';
import * as fsaManagementActions from '../../data/reducers/fsamanagement.reducer';
import { set } from 'react-hook-form';

const { Sider, Content } = Layout;
const { Search } = Input;

type FSAData = {
  id: string;
  ambassadorReferralId: string;
  phoneNumber: string;
  ambassadorName: string;
  stores: StoreInfo[];
};

type StoreInfo = {
  storeName: string;
  storeId: string;
};

const FsaManagement: React.FC = () => {
  const [managers, setManagers] = useState<FSAData[]>([]);
  const [selectedManager, setSelectedManager] = useState<FSAData | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchMerchantStoresTerm, setSearchMerchantStoresTerm] = useState<string>('');
  const [managerSearchTerm, setManagerSearchTerm] = useState<string>('');

  const dispatch = useDispatch<AppDispatch>();
  const {
    fsaList,
    isFsaListLoading,
    isFsaListError,
    fsaListError,
    fsaMerchants,
    isFsaMerchantsLoading,
    isFsaMerchantsError,
    fsaMerchantsError,
    merchantSearchResult,
    isMerchantsSearchLoading,
    isMerchantsSearchError,
    merchantsSearchError,
  } = useSelector((state: RootState) => state.fsaManagement);

  useEffect(() => {
    dispatch(fsaManagementActions.GetFSAsList(null));
  }, []);

  useEffect(() => {
    if (fsaList?.length > 0 && fsaList.length !== managers.length) {
      const managersList: FSAData[] = [];
      fsaList.forEach((fsa) => {
        managersList.push({
          id: fsa.id,
          ambassadorReferralId: fsa.ambassadorReferralId,
          phoneNumber: fsa.phoneNumber,
          ambassadorName: fsa.ambassadorName,
          stores: [],
        });
      });

      setManagers(managersList);
    }
  }, [fsaList]);

  useEffect(() => {
    if (selectedManager && !selectedManager?.stores?.length) {
      dispatch(fsaManagementActions.GetFSAMerchants({ fsaCode: selectedManager.ambassadorReferralId }));
    }
  }, [selectedManager]);

  useEffect(() => {
    if (
      fsaMerchants?.length > 0 &&
      (!selectedManager?.stores || fsaMerchants.length !== selectedManager.stores.length)
    ) {
      const updatedStores = fsaMerchants.map((merchant) => ({
        storeName: merchant.storeName,
        storeId: merchant.merchantStoreId,
      }));

      if (JSON.stringify(selectedManager?.stores) !== JSON.stringify(updatedStores)) {
        const updatedManager: FSAData = {
          id: selectedManager?.id ?? 'NA',
          ambassadorReferralId: selectedManager?.ambassadorReferralId ?? 'NA',
          phoneNumber: selectedManager?.phoneNumber ?? 'NA',
          ambassadorName: selectedManager?.ambassadorName ?? 'NA',
          stores: updatedStores,
        };
        setSelectedManager(updatedManager);
      }
    }
  }, [fsaMerchants]);

  const handleManagerClick = (manager: FSAData) => setSelectedManager(manager);

  const handleStoreRemove = (store: StoreInfo) => {
    dispatch(fsaManagementActions.UnassignFSAMerchant({ merchantStoreId: store.storeId }));

    if (!selectedManager) return;
    const updatedManager: FSAData = {
      ...selectedManager,
      stores: selectedManager.stores.filter((s) => s.storeId !== store.storeId),
    };
    setSelectedManager(updatedManager);
  };

  const handleStoreAdd = (store: MerchantStore) => {
    if (!selectedManager) return;
    dispatch(
      fsaManagementActions.UpdateFSAMerchantAssignment({
        fsaCode: selectedManager.ambassadorReferralId,
        merchantStoreIds: [store.merchantStoreId],
      }),
    );

    const updatedManager: FSAData = {
      ...selectedManager,
      stores: [...selectedManager.stores, { storeName: store.storeName, storeId: store.merchantStoreId }],
    };
    setSelectedManager(updatedManager);
    // setIsModalVisible(false);
  };

  useEffect(() => {
    if (searchMerchantStoresTerm.length > 2) {
      dispatch(fsaManagementActions.SearchMerchantsByFSACodeOrPhone({ searchQuery: searchMerchantStoresTerm }));
    }
  }, [searchMerchantStoresTerm]);

  return (
    <Layout style={{ height: '80vh' }}>
      <Sider theme="light" width={300}>
        <h3 style={{ paddingLeft: '20px', paddingTop: '20px' }}>FSAs</h3>
        <div style={{ padding: '20px', paddingTop: '10px' }}>
          <Search
            placeholder="Search FSAs..."
            onChange={(e) => setManagerSearchTerm(e.target.value)}
            enterButton={<SearchOutlined />}
          />
        </div>
        <div style={{ overflowY: 'auto', height: '68vh' }}>
          {isFsaListLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
              <Spin />
            </div>
          ) : (
            <List
              dataSource={managers.filter((manager) =>
                manager.ambassadorName.toLowerCase().includes(managerSearchTerm.toLowerCase()),
              )}
              renderItem={(manager) => (
                <List.Item
                  style={{
                    cursor: 'pointer',
                    background: selectedManager?.id === manager.id ? '#f0f2f5' : '',
                    padding: '10px',
                  }}
                  onClick={() => handleManagerClick(manager)}
                >
                  {manager.ambassadorName}<br/>
                  <span style={{color: "rgba(0, 0, 0, 0.45)"}}>{manager.ambassadorReferralId}</span>
                </List.Item>
              )}
            />
          )}
        </div>
      </Sider>
      <Content style={{ padding: '20px' }}>
        {selectedManager ? (
          <Card title={`Stores managed by ${selectedManager.ambassadorName}`}>
            <Search
              placeholder="Search stores..."
              onChange={(e) => setSearchTerm(e.target.value)}
              enterButton={<SearchOutlined />}
            />
            {isFsaMerchantsLoading ? (
              <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                <Spin />
              </div>
            ) : (
              <List
                dataSource={selectedManager.stores.filter((store) =>
                  store.storeName.toLowerCase().includes(searchTerm.toLowerCase()),
                )}
                renderItem={(store) => (
                  <List.Item
                    actions={[
                      <Popconfirm
                        key={1}
                        title="Are you sure you want to unassign this store?"
                        onConfirm={() => handleStoreRemove(store)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="text" icon={<UserDeleteOutlined />} danger />
                      </Popconfirm>,
                    ]}
                  >
                    <List.Item.Meta title={store.storeName} description="Store assigned to this manager" />
                  </List.Item>
                )}
              />
            )}
            <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsModalVisible(true)}>
              Add Store
            </Button>
          </Card>
        ) : (
          <p>Select a manager to view their stores.</p>
        )}
      </Content>

      {/* Modal for adding stores */}
      <Modal title="Add Store" open={isModalVisible} onCancel={() => setIsModalVisible(false)} footer={null}>
        <Search
          placeholder="Search stores..."
          onChange={(e) => setSearchMerchantStoresTerm(e.target.value)}
          enterButton={<SearchOutlined />}
        />
        <List
          dataSource={merchantSearchResult}
          renderItem={(store: MerchantStore) => (
            <List.Item
              actions={[
                <Popconfirm
                  key={2}
                  title={`Are you sure you want to assign store to ${selectedManager?.ambassadorReferralId ?? 'FSA'}?`}
                  //   onConfirm={() => console.log('Add store')}
                  onConfirm={() => handleStoreAdd(store)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="text" icon={<UserAddOutlined />} />
                </Popconfirm>,
              ]}
            >
              <List.Item.Meta
                title={store.storeName}
                description={`${store.ownerUsers[0].fullName} | ${store.assignedFSA ?? 'Unassigned'}`}
              />
            </List.Item>
          )}
        />
      </Modal>
    </Layout>
  );
};

export default FsaManagement;
