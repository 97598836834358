import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './auth.reducer';
import dashboardReducer from './dashboard.reducer';
import tutorialsReducer from './tutorials.reducer';
// import / from "./tutorials.reducer";
import fsareportReducer from './fsareport.reducer';
import yuorderpromoReducer from './yuorderpromo.reducer';
import wholesaleReducer from './wholesale.reducer';
import fsaManagementReducer from './fsamanagement.reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  tutorials: tutorialsReducer,
  fsaMerchantreport: fsareportReducer,
  yuorderpromos: yuorderpromoReducer,
  wholesale: wholesaleReducer,
  fsaManagement: fsaManagementReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
