import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IssuedCouponData, YuorderCoupon } from '../../data/models/yuorderCoupon.model';

export const CouponToast = (coupon: IssuedCouponData) => {
  return (
    <div style={{ fontFamily: 'Arial, sans-serif', padding: '10px' }}>
      <h4 style={{ margin: 0, color: '#2d3436' }}>Coupon Code</h4>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          border: '1px dashed #0984e3',
          borderRadius: '8px',
          padding: '8px',
          margin: '5px 0',
        }}
      >
        <p
          style={{
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#0984e3',
            margin: 0,
            flex: 1,
          }}
        >
          {coupon.couponCode}
        </p>
        <button
          onClick={() => {
            navigator.clipboard.writeText(coupon.couponCode);
            toast.success('Coupon code copied to clipboard', {
              position: 'bottom-center',
              autoClose: 1500,
            });
          }}
          style={{
            marginLeft: '10px',
            padding: '4px 12px',
            backgroundColor: '#0984e3',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '14px',
          }}
        >
          Copy
        </button>
      </div>

      {coupon.title && <p style={{ margin: '5px 0', color: '#636e72' }}>{coupon.title}</p>}
      {coupon.maxDiscountAmount !== null && coupon.maxDiscountAmount !== undefined && (
        <p style={{ margin: '5px 0', color: '#00b894' }}>Amount: ₱{coupon.maxDiscountAmount}</p>
      )}
    </div>
  );
};
